// URL PARAMS
const urlParams = new URLSearchParams(window.location.search);
const defaultAvatarType = 'gptCEU';
export const avatarType = urlParams.get('avatar') || defaultAvatarType;
export const usuario = urlParams.get('usuario');
export const apiUrl =
  avatarType?.toLowerCase() === 'gptceu'
    ? process.env.REACT_APP_GPT_CEU_API
    : process.env.REACT_APP_CEU_API;

// AZURE SPEECH
export const SPEECH_REGION = 'westeurope';
export const SPEECH_REGION_REPLACE = 'SPEECH_REGION';
export const VOLUMEN_ON = true;
export const VOLUMEN_OFF = false;

// OTHERS
export const FILE_NAME_MAX_LENGTH = 10;
export const DESARROLLO_MODE = 'des';
